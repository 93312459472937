/* eslint-disable import/first */
import React from "react";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";

// components
import { ButtonCTADarkest } from "../components/buttonCTA";

// styles
import { ContentInfo, ContentInfoCTA, SubInfo, Label, HeaderSpacer, Form, ArticleTitle, ContentTitle, Title, ArticleInfo } from "../styles/mentoria-pensamental-10x";
import SEO from "../components/seo";
import Main from "../components/main";
import Header from "../components/header";
import Footer from "../components/footer";

// steps
function StepBase({ children, isNavigatinBackwards }) {
  const initial = { x: isNavigatinBackwards ? -1000 : 1000 };

  return (
    <motion.div className="text-center w-full max-w-screen-md flex flex-col" transition={{ duration: 0.5, type: "spring", stiffness: 100 }} initial={initial} animate={{ x: 0 }}>
      {children}
    </motion.div>
  );
}

function NotFoundPage() {
  return (
    <>
      <SEO title="| Página não encontrada" />
      <Main className="z-30">
        <Header />
        <HeaderSpacer />

        <Form>
          <ArticleTitle>
            <ContentTitle>
              <Title>Página não encontrada</Title>
            </ContentTitle>
          </ArticleTitle>

          <ArticleInfo>
            {true && (
              <StepBase>
                <ContentInfo>
                  <SubInfo>Oops, algo não está certo!</SubInfo>
                  <Label>Não conseguimos achar a página que você tentou acessar.</Label>
                  <StaticImage src="../images/fail.png" alt="Falha" placeholder="blurred" imgStyle={{ objectFit: "contain" }} height={250} />
                </ContentInfo>

                <ContentInfoCTA $centered>
                  <ButtonCTADarkest to="/" className="w-full sm:w-2/5 text-center">
                    VOLTAR PARA HOME
                  </ButtonCTADarkest>
                </ContentInfoCTA>
              </StepBase>
            )}
          </ArticleInfo>
        </Form>

        <Footer isDarkest />
      </Main>
    </>
  );
}

export default NotFoundPage;
